import React, { useState } from "react";


    const Domain = ()=>{
        const [domainvalue, setDomainvalue] = useState('');

        const handleInputChange = (event) =>{
            setDomainvalue(event.target.value);
        }

        const handleSubmit = async (event) => {
            event.preventDefault();

            const baseUrl = window.location.origin+'/billing/cart.php';

            const query = new URLSearchParams({
                a: 'add',
                domain: 'register', // Modify as needed
                query: domainvalue,
            }).toString();

            window.location.href = baseUrl+'?'+query;
        }

    return (
        <section className="domain_element_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-8">
                        <div className="input-group-wrapper">
                            <form className="domain-search-form" onSubmit={handleSubmit} id="frmDomainHomepage">
                            <input type="text"
                    className="form-control"
                    name="domain"
                    value={domainvalue}
                    placeholder="eg. example.com"
                    autoCapitalize="none"
                    onChange={handleInputChange}
                    required
                    data-toggle="tooltip"
                    data-placement="left"
                    data-trigger="manual"
                    title="Required"/>
                                <button className="btn domain-search-btn" type="submit">
                                    <span className="spinner me-2"><i className="fa-solid fa-search"></i></span>
                                    <span className="button-text">search</span>
                                </button>
                            </form>
                       <ul className="form-group">
                        <li className="item">.com</li>
                        <li className="item">.com.my</li>
                        <li className="item">.asia</li>
                        <li className="item">.sg</li>
                        <li className="item">.au</li>
                        <li className="item">.edu</li>
                        <li className="item">.shop</li>
                        <li className="item">.biz</li>
                       </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Domain;