import {createContext, useContext, useEffect, useState} from "react";

const ConfigContext = createContext();


export const useConfig = () => {
    return useContext(ConfigContext);
}

export const ConfigProvider = ({children}) => {
    const [configFile, setConfigFile] = useState({
        'favicon' : require('./../assets/images/favicon.png'),
        'description' : "Bigeweb Solution hosting and domain provider",
        'author' : "Bigeweb solution",
        'site_name' : "Bigeweb solution",
        'keywords' : "Web development, theme and templates, hosting, professional email",
        'google_site_verification' : "oTJlUNO7mzaPV0cYE2q29wACPPp5TUUoSx0u6GK7NnI",
        'google_ads_account' : "ca-pub-5934397621272516",
        'google_places' : "",
        'logo' :  require('./../assets/images/New Logo Black.png'),
        'site_language' : 'ms',
        'hotline' : '+60102013950',
        'contact_email' : 'csc@bigeweb.com',
        'show_site_name_after_title' : 0,
        'copyright_text' : 'Copyright© Bigeweb Solution 2024',
    });

    const [ApiError, setApiError] = useState(null);

    useEffect(() => {
       const Api_url = process.env.REACT_APP_API_URL;

       const fetchConfig = async () =>{
          try{
              const response = await fetch(Api_url+'/api/appearance-configuration',{
                  "content-type" : "application/json",
                  "Accept" : "application/json",
                  "Cache-Control" : "no-cache"
              });
             if(!response.ok)
             {
                 setApiError("Network is not okay");
             }

           let result = await response.json();

             if(result.status && result.status === 200)
             {
                 setConfigFile(configFile =>({
                     ...configFile,
                     "logo" : Api_url+'/'+result.data.logo,
                     "favicon" : Api_url+'/'+result.data.favicon,
                 }) );

             }
          }catch (e)
          {
            setApiError(e.message);
          }
       }


       const fetchPref = async () =>{
           try{
               let response = await fetch(Api_url+'/api/system-details',{
                   "content-type" : "application/json",
                   "Accept" : "application/json",
                   "Cache-Control" : "no-cache"
               });



               if(!response.ok)
               {
                   setApiError("Error connecting to Network");
               }
               let result = await response.json();
              if(result.status === 200)
              {
                  setConfigFile(configFile => ({
                      ...configFile,
                      'hotline' : result.advance_option.hotline,
                      'contact_email' : result.advance_option.email,
                      'site_language' : result.system_details.language,
                      'site_name' : result.system_details.site_name,
                      'show_site_name_after_title' : result.advance_option.title_option,
                      'description' : result.seo.description,
                      'keywords' : result.seo.keywords,
                      'copyright_text' : result.copyright,
                  }));

                  if(result.googleApi)
                  {
                      setConfigFile(configFile => ({
                          ...configFile,
                          'google_site_verification' : result.googleApi.gsite_verification,
                          'google_ads_account' : result.googleApi.g_analytics,
                          'google_places' : result.googleApi.g_places,
                      }));
                  }
              }

           }catch (e)
           {
               setApiError(e.message);
           }
       }
        fetchConfig();
        fetchPref();
    }, []);




    return <ConfigContext.Provider value={configFile}>
        {children}
    </ConfigContext.Provider>
}