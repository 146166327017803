import React, {createContext, useContext, useEffect, useState} from 'react';

const MenuContext = createContext();

export const useMenu = () => {
    return useContext(MenuContext);
}

export const MenuProvider = ({children}) => {
    const [Menus, setMenus] = useState([]);
    const [ApiError, setApiError] = useState(null);


    useEffect(() => {
        const FetchMenu = async (event) => {
            let Api_Url = process.env.REACT_APP_API_URL+'/api/menus/fetch';
            try{
                const response = await fetch(Api_Url,
                    {
                        method : "GET",
                        header : {
                            "content-type" : "application/json",
                            "Accept" : "application/json",
                            "Cache-Control" : "no-cache"
                        }
                    });

                if(!response.ok)
                {
                    setApiError("Unable to connect due to network error!");
                }

                let result = await response.json();
                if(result.status === 200)
                {
                    setMenus(result.menus);
                }
            }catch (exception)
            {
                setApiError(exception.message);
            }
        }

        FetchMenu();
    }, []);

    return (
        <MenuContext.Provider value={Menus}>
            {children}
        </MenuContext.Provider>
    )
}