import React from "react";

function Newsletter()
{
    return (
        <div className="__user-subscriber animate-content-from-down-content">
    <div className="container">
        <div className="row justify-content-center align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6">
                <div className="text-container">
                    <h1 className="title">
                    Subscribe to our newsletter                    </h1>
                    <p className="text">
                       Get more information and news about products and discount vouchers                    </p>
                </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
                <form action="#" className="subscriber-form">
                    <input type="email" className="form-control" name="email" id="email" placeholder="Email address" />
                    <button className="btn" id="subscribe-e-news" type="button" data-url="https://bigeweb.com/newsletter/subscribe">
                        <span className="button-text">subscribe</span>
                        <span className="loading-icon"><i className="fa-solid fa-spinner"></i></span>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
    );
}

export default Newsletter;