import React, {createContext, useContext, useEffect, useState} from "react";


const CodeSnippetContext = createContext();

export const useCodeSnippet = () => {
    return useContext(CodeSnippetContext);
};


export const CodeSnippetProvider = ({children}) => {
    const [snippet, setSnippet] = useState();
    const [ApiError, setApiError] = useState('');



    useEffect(() => {
        const FetchSnippet = async (event) => {
            let Api_Url = process.env.REACT_APP_API_URL+'/api/system/custom-code';

            try{
                let response = await fetch(Api_Url, {
                    method : "GET",
                    header : {
                        "content-type" : "application/json",
                        "Accept" : "application/json",
                        "Cache-Control" : "no-cache"
                    }
                });
                if(!response.ok)
                {
                    setApiError("Unable to connect. Please check connection and try again");
                }

                let result = await response.json();
                if(result.status === 200)
                {
                    const styles = document.createElement('style');
                    styles.append(result.snippet.css)
                    document.head.appendChild(styles);

                    const script = document.createElement("script");
                    script.append(result.snippet.js);
                    script.async = true;
                    document.body.appendChild(script);
                }
            }catch(exception)
            {
                setApiError(exception.message);
            }

        }
        FetchSnippet();
    }, []);



    return (
        <CodeSnippetContext.Provider value={snippet}>
            {children}
        </CodeSnippetContext.Provider>
    )

}