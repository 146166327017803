import React from "react";

function Feature()
{
        return (
            <section className="feature-wrapper">
                 <div className="animation">
                        <div className="ani-item"></div>
                        <div className="ani-item"></div>
                        <div className="ani-item"></div>
                        <div className="ani-item"></div>
                        <div className="ani-item"></div>
                        <div className="ani-item"></div>
                        <div className="ani-item"></div>
                    </div>
                <div className="container">
                    <h4 className="title">Discover Why Bigeweb is Your Go-To for Domain and Hosting Solution</h4>
                    <div className="row g-3">
                        <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="card">
                                    <div className="card-header">
                                        <p className="text">Affordable Pricing</p>
                                    </div>
                                    <div className="card-body">
                                        <p className="text">Bigeweb offers competitive pricing on both domain registration and hosting plans, ensuring you get great value without compromising on quality.</p>
                                    </div>
                                </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="card">
                                    <div className="card-header">
                                        <p className="text">Reliable Performance</p>
                                    </div>
                                    <div className="card-body">
                                        <p className="text">Enjoy fast and reliable hosting with high uptime guarantees, ensuring your website is always accessible to your visitors.</p>
                                    </div>
                                </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="card">
                                    <div className="card-header">
                                        <p className="text">24/7 Customer Support</p>
                                    </div>
                                    <div className="card-body">
                                        <p className="text">Our dedicated support team is available around the clock to assist you with any questions or issues you may encounter, providing peace of mind for your online presence.</p>
                                    </div>
                                </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="card">
                                    <div className="card-header">
                                        <p className="text">User-Friendly Interface</p>
                                    </div>
                                    <div className="card-body">
                                        <p className="text">With our easy-to-use control panel, managing your domain and hosting settings is simple, even for beginners. Get your site up and running in no time!</p>
                                    </div>
                                </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="card">
                                    <div className="card-header">
                                        <p className="text">Comprehensive Security Features</p>
                                    </div>
                                    <div className="card-body">
                                        <p className="text">Bigeweb prioritizes your online safety with robust security measures, including SSL certificates, regular backups, and malware protection to keep your website secure.</p>
                                    </div>
                                </div>
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="card">
                                    <div className="card-header">
                                        <p className="text">Flexible Plans</p>
                                    </div>
                                    <div className="card-body">
                                        <p className="text">We offer a variety of hosting plans tailored to meet your specific needs, whether you’re running a personal blog or a large e-commerce site, making it easy to scale as your business grows.</p>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </section>
        );
}

export default Feature;