import React from "react";
import {createBrowserRouter} from "react-router-dom";
import App from "../App";
import Homepage from "../Frontend/Homepage/Homepage";
import DomainChecker from "../Frontend/Domain/DomainChecker";
import SharedHosting from "../Frontend/Products/SharedHosting";
import WordpressHosting from "../Frontend/Products/WordpressHosting";
import NoResult from "../Frontend/Errors/NoResult";
import NoPage from "../Frontend/Errors/NoPage";
import ArticlePage from "../Frontend/ArticlePage";
import Contact from "../Frontend/Contact";
import Design from "../Frontend/Products/Design";


 const Router = createBrowserRouter([
     {
         path : "/",
         element : <App/>,
         children : [
             {
                 path : "/",
                 element : <Homepage/>
             },
             {
                 path:"/domains",
                 element:<DomainChecker/>
             },
             {
                 path:"/cpanel-hosting/:category",
                 element: <SharedHosting/>
             },
             {
                 path:"/wordpress-hosting/:category",
                 element: <WordpressHosting/>
             },
             {
                 path:"/web-design-hosting/:category",
                 element: <Design/>
             },
             {
                 path:"/no-product",
                 element: <NoResult/>
             },
             {
                 path : "*",
                 element: <NoPage/>
             },
             {
                 path : "/error-404",
                 element: <NoPage/>
             },
             {
                 path : "/article/:page",
                 element: <ArticlePage/>
             },
             {
                 path : "/contact",
                 element: <Contact/>
             }
         ]
     }
 ]);

 export default Router;

