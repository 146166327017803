import React, {createContext, useContext, useEffect, useState} from "react";
import {Navigate} from "react-router-dom";


const ProductContext = createContext();


    export const useProduct = () =>{
        return useContext(ProductContext);
    }

    export const ProductProvider = ({children}) => {

        const [product, setProduct] = useState([]);
        const [ApiError, setApiError] = useState(null);
        const [resCode, setResCode] = useState(200);

        useEffect(() => {
            let pageURL = window.location.href;
            let urlQueryString = pageURL.split('/');
            urlQueryString = urlQueryString.pop();

            let Api_Url = process.env.REACT_APP_API_URL+
                '/api/products/fetch/?product-group='+
                urlQueryString;

            const fetchProduct = async () => {
                try{
                    const response = await fetch(Api_Url, {
                        method : "GET",
                        header : {
                            "content-type" : "application/json",
                            "Accept" : "application/json",
                            "Cache-Control" : "no-cache"
                        }
                    });

                    if(!response.ok)
                    {
                        setApiError("Unable to connect to network");
                    }

                    const result = await response.json();
                   if(result.status === 200)
                   {
                        setProduct(result.products);
                   }else{
                       setResCode(400);
                   }

                }catch (exception)
                {
                    setApiError(exception.message);
                }
            }

            fetchProduct();
        }, []);

        if(resCode === 400)
        {
            return <Navigate to={"/no-product"} />
        }


        return (
            <ProductContext.Provider value={product}>
            {children}
        </ProductContext.Provider>
        )

    }

