import React, {useState} from "react";
import {configFile} from "../Config/Constant";
import {showSuccessMessage, showErrorMessage, showDangerButton, stopLoadingButton, loading_button} from "../assets/js/validation";
import {Navigate} from "react-router-dom";
import {useCompanyProfile} from "../Context/CompanyProfileContext";

function Contact()
{
    let profile = useCompanyProfile();
    const [formData, setFormData] = useState({
        complaint_type : '',
        attachment : '',
        full_name : '',
        mobile : profile.default_tel_code,
        email : '',
        description : '',
    });


    const handleInputChange = (event) => {
      const  {name, value, type, files} = event.target;
      setFormData(formData => ({
        ...formData,
          [name] : type === 'file' ? files[0] : value,
      }))
    }

    const [ApiError, setApiError] = useState('');

    const submitContactForm = async (event) => {
        event.preventDefault();
        let self =  "#"+event.currentTarget.getAttribute("id");
        loading_button(self);

       const Data = new FormData();
        Data.append('complaint_type', formData.complaint_type);
        Data.append('file', formData.attachment);
        Data.append('full_name', formData.full_name);
        Data.append('mobile', formData.mobile);
        Data.append('email', formData.email);
        Data.append('description', formData.description);

        let Api_url = process.env.REACT_APP_API_URL+'/contact/send';

        try{
            let response = await fetch(Api_url,
                {
                    method : "POST",
                    header : {
                        // "content-type" : "application/json",
                        "Accept" : "application/json",
                        "Cache-Control" : "no-cache"
                    },
                    body : Data
                });

           if(!response.ok)
           {
               setApiError("Unable to connect, Network response was not okay")
           }

           let result = await response.json();
          if(result.status === 400)
          {
              showDangerButton(self, result.errors);
              setTimeout(function ()
              {
                  stopLoadingButton(self, 'danger');
              }, 3000);
          }else if(result.status === 200)
          {
                showSuccessMessage(self, result.message);
              setTimeout(function ()
              {
                  stopLoadingButton(self, 'success');
                  window.location.href = window.location.origin+"/contact";
              }, 3000);
          }
        }catch (e)
        {
            showErrorMessage(self, e.message);
            setTimeout(function ()
            {
                stopLoadingButton(self, 'danger');
            }, 3000);
        }

    }


    return (
       <section id="homepage">
            <div className="top-bar">
                <h3 className="title">contact us</h3>
            </div>
           <div className="main-content">
               <div className="container">
                   <div className="graph-wrapper">
                       <iframe allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                               src="https://www.google.com/maps/embed/v1/place?q=19a+Jalan+pu7/3+puchong+utama+selangor+47100&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
                       </iframe>
                   </div>
                   <div className="header">
                       <div className="content">
                           <h1 className="title">Need some help?</h1>
                           <p className="text">Feel free to share your concerns with us. Let us solve your problems with
                               a single click. We're here to help!.</p>
                       </div>
                   </div>
                   <div className="wrapper">
                       <div className="row g-3 align-items-center">
                           <div className="col-sm-12 col-md-6 col-lg-6">
                               <form id="data-form">
                                   <div className="row g-3 mb-3">
                                       <div className="col-sm-12">
                                           <label htmlFor="complaint_type" className="form-label astar">Reason for your
                                               message?</label>
                                           <select onChange={handleInputChange} name="complaint_type" id="complaint_type" className="form-select" value={formData.complaint_type}>
                                               <option value="">--- please choose one ---</option>
                                               <option value="pre sales">Pre-Sales Inquiry</option>
                                               <option value="technical support">Technical Support</option>
                                               <option value="billing">Billing Question</option>
                                               <option value="account management">Account Management</option>
                                               <option value="feedback">Feedback/Suggestions</option>
                                               <option value="cancellation">Service Cancellation</option>
                                               <option value="partnership">Partnership Inquiry</option>
                                               <option value="others">others</option>
                                           </select>
                                       </div>
                                       <div className="col-sm-12 col-md-12 col-lg-12">
                                           <label htmlFor="attachment" className="form-label">attachment <i
                                               className="text-gray-700">(optional)</i></label>
                                           <input type="file" className="form-control" onChange={handleInputChange} name="attachment"
                                                  id="attachment"/>
                                       </div>
                                       <div className="col-sm-12 col-md-12 col-lg-12">
                                           <label htmlFor="name" className="form-label astar">full name</label>
                                           <input type="text" className="form-control" id="full_name" onChange={handleInputChange} name="full_name" value={formData.full_name}/>
                                       </div>
                                       <div className="col-sm-12 col-md-6 col-lg-6">
                                           <label htmlFor="mobile" className="form-label astar">contact number</label>
                                           <input type="tel" className="form-control" onChange={handleInputChange} name="mobile" id="mobile"
                                                  value={formData.mobile}/>
                                       </div>
                                       <div className="col-sm-12 col-md-6 col-lg-6">
                                           <label htmlFor="email" className="form-label astar">email</label>
                                           <input type="email" className="form-control" onChange={handleInputChange} name="email" id="email"
                                                  value={formData.email}/>
                                       </div>
                                       <div className="col-sm-12 col-md-12 col-lg-12">
                                           <label htmlFor="description" className="form-label astar">details about the
                                               option selected above</label>
                                           <textarea onChange={handleInputChange} name="description" id="description" cols="3" rows="3"
                                                     className="form-control" value={formData.description}></textarea>
                                       </div>
                                   </div>
                                   <div className="mb-3 text-center">
                                       <button className="btn send-msg-btn create-btn" type="button"
                                               onClick={submitContactForm} data-url="" id="store-data-btn">
                                           <span className="btn-text">send <i className="fa-solid fa-chevron-right"></i></span>
                                           <span className="spinner"><i className="fa-solid fa-spinner"></i></span>
                                           <span className="loading-text">sending request...</span>
                                       </button>
                                   </div>
                               </form>
                           </div>
                           <div className="col-sm-12 col-md-6 col-lg-6">
                               <div className="thumbnail-wrapper">
                                   <img src={configFile.contact_us_img} alt="contact-us"
                                        className="img-fluid"/>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </section>
    )
}

export default Contact;