import React, {useEffect, useState} from "react";
import FeaturePricing from "./FeaturePricing";
import {ProductProvider} from "../../Context/ProductContext";
import {configFile} from "../../Config/Constant";
import {useConfig} from "../../Context/ConfigContext";
import DesignFaq from "./DesignFaq";
import DesignShowcase from "./DesignShowcase";

function Design()
{
    let currentSlug = window.location.href.split('/');
    currentSlug =currentSlug.pop();
    const [Item, setItem] = useState(null);
    const configData = useConfig();

    useEffect(() =>{
        const fetchCategory = async () => {
            let Api_Url = process.env.REACT_APP_API_URL+'/api/products/category/?slug='+currentSlug;

            try{
                const response = await fetch(Api_Url, {
                    method : "GET",
                    header : {
                        "content-type" : "application/json",
                        "Accept" : "application/json",
                        "Cache-Control" : "no-cache"
                    }
                });

                if(!response.ok)
                {
                    console.log("Network is bad");
                }

                let result = await response.json();
                if(result.status === 200)
                {
                    setItem(result.data);
                    if(configData.show_site_name_after_title === 1)
                    {
                        document.title=result.data.name +" - " + configData.site_name;
                    }else {
                        document.title=result.data.name;
                    }
                    document.description=result.data.short_description

                }

            }catch (e)
            {
                console.log(e.message);
            }
        }

        fetchCategory();
    }, [])

    return (
        <ProductProvider>
            <section id="homepage">
                <div className="design-pg-wrapper">
                    <div className="banner">
                        <img src={configFile.web_design_banner} alt="web-design photo" className="img-fluid"/>
                    </div>
                    <div className="container">
                        <div className="bottom-slider-wrapper">
                            <h3 className="title">{Item ? (Item.name) : null}</h3>
                            <p className="text" dangerouslySetInnerHTML={{__html: Item ? (Item.short_description) : null}}></p>
                        </div>
                    </div>
                </div>
                <FeaturePricing/>
                <div className="container">
                    <div className="mobil-wrapper">
                        <div className="row g-3">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <img src={configFile.web_page_collection} alt="web page collections"
                                     className="img-fluid"/>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                               <h3 className="title">Our ui/ux design feature.</h3>
                                <ul className="features">
                                    <li>
                                        <h3 className="title">Responsive Design</h3>
                                        <p className="text">Providing an optimal viewing experience across all devices,
                                            which is essential for user retention and SEO.</p>
                                    </li>
                                    <li>
                                        <h3 className="title">Fast Loading Speed</h3>
                                        <p className="text">Quick loading times, reducing bounce rates and improving user satisfaction.</p>
                                    </li>
                                    <li>
                                        <h3 className="title">Content Management System (CMS)</h3>
                                        <p className="text">User-friendly CMS that allows clients to easily update their content, ensuring their site remains current without needing technical expertise.</p>
                                    </li>
                                    <li>
                                        <h3 className="title">Analytics and seo Integration</h3>
                                        <p className="text">Tools like Google Analytics to help clients track visitor behavior, enabling data-driven decisions for ongoing improvements.</p>
                                    </li>
                                    <li>
                                        <h3 className="title">Security Features</h3>
                                        <p className="text">security measures, including SSL certificates and regular updates, to protect both the website and user data.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="designer">
                    <DesignShowcase />
                </div>
                <DesignFaq />
            </section>
        </ProductProvider>
    )
}

export default Design;