import React from "react";
import {configFile} from "../../Config/Constant";

function NoResult()
{
    return (
        <section id="homepage">
           <div className="error-container-wrapper">
               <div className="container">
                   <img src={configFile.no_product} alt="no product" className="img-fluid" loading="lazy"/>
                   <h2>No Products Found</h2>
                   <p>Sorry, we couldn't find any products matching your criteria.</p>
               </div>
           </div>
        </section>
    );
}

export default NoResult;