import React, {useEffect, useState} from "react";
import { configFile } from '../../Config/Constant';
import {useProduct} from "../../Context/ProductContext";
import {useConfig} from "../../Context/ConfigContext";

function Slider()
{
     let currentSlug = window.location.href.split('/');
     currentSlug =currentSlug.pop();
     const [Item, setItem] = useState(null);
     const configData = useConfig();

     useEffect(() =>{
        const fetchCategory = async () => {
            let Api_Url = process.env.REACT_APP_API_URL+'/api/products/category/?slug='+currentSlug;

            try{
                const response = await fetch(Api_Url, {
                    method : "GET",
                    header : {
                        "content-type" : "application/json",
                        "Accept" : "application/json",
                        "Cache-Control" : "no-cache"
                    }
                });

                if(!response.ok)
                {
                    console.log("Network is bad");
                }

                let result = await response.json();
                if(result.status === 200)
                {
                    setItem(result.data);
                    if(configData.show_site_name_after_title === 1)
                    {
                        document.title=result.data.name +" - " + configData.site_name;
                    }else {
                        document.title=result.data.name;
                    }
                        document.description=result.data.short_description
                }

            }catch (e)
            {
                console.log(e.message);
            }
        }

        fetchCategory();
     }, [])

    return (
        <section id="slider">
        <div className="slider-wrapper domain-slider">
            <div className="animation">
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
                <div className="ani-item"></div>
            </div>
            <div className="inner-content">
                <div className="item">
                   <div className="container">
                   <div className="row justify-content-center">
                       {
                           Item ? (
                               <>
                                   <div className="col-sm-12 col-md-6 col-lg-6">
                                       <h3 className="title">{Item.name}</h3>
                                       <p className="text" dangerouslySetInnerHTML={{__html: Item.short_description}}></p>
                                   </div>
                                   <div className="col-sm-12 col-md-6 col-lg-6">
                                       {
                                           Item.module === 'cpanel' ? (
                                               <img src={configFile.Hosting_hero} alt="hosting banner"
                                                    className="img-fluid"/>
                                           ) : (
                                               <img src={configFile.wordpress_hosting_image} alt="hosting banner"
                                                    className="img-fluid"/>
                                           )
                                       }
                                   </div>
                               </>
                           ) : null
                       }
                   </div>
                   </div>
                </div>
            </div>
        </div>
        </section>
    );
}

export default Slider;