import React from "react";

function SharedFaq()
{

    const handleAccordionButton = (event) =>{
        const accordion = document.querySelectorAll(".accordion");
        if(accordion.length > 0)
        {
            accordion.forEach(acc =>{
                acc.classList.remove("active");
            });
        }
       event.target.closest(".accordion").classList.add("active");
    }
        return (
            <section className="accordion-wrapper">
               <div className="container">
               <h4 className="title">FAQs about cPanel web hosting</h4>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>What is cPanel?</button>
                    <div className="accordion-content">
                        <p className="text">cPanel is a web-based control panel that simplifies website management, allowing users to manage files, domains, and databases easily.</p>
                    </div>
            </div>
            <div className="accordion">
                <button className="btn accordion-button" onClick={handleAccordionButton}>How do I access cPanel?</button>
                <div className="accordion-content">
                    <p className="text">You can access cPanel by entering yourdomain.com/cpanel in your web browser and logging in with your credentials.</p>
                </div>
            </div>
            <div className="accordion">
                <button className="btn accordion-button" onClick={handleAccordionButton}>Can I install applications using cPanel?</button>
                <div className="accordion-content">
                    <p className="text">Yes, cPanel includes tools like Softaculous that allow you to install popular applications like WordPress, Joomla, and more with just a few clicks.</p>
                </div>
            </div>
            <div className="accordion">
                <button className="btn accordion-button" onClick={handleAccordionButton}>What is the File Manager in cPanel?</button>
                <div className="accordion-content">
                    <p className="text">The File Manager is a built-in tool that lets you upload, delete, and organize files on your server without needing FTP access.</p>
                </div>
            </div>
            <div className="accordion">
                <button className="btn accordion-button" onClick={handleAccordionButton}>How do I create an email account in cPanel?</button>
                <div className="accordion-content">
                    <p className="text">Go to the "Email Accounts" section in cPanel, enter the desired email address and password, then click "Create."</p>
                </div>
            </div>
            <div className="accordion">
                <button className="btn accordion-button" onClick={handleAccordionButton}>What is the backup feature in cPanel?</button>
                <div className="accordion-content">
                    <p className="text">cPanel offers backup tools that allow you to create full or partial backups of your website, ensuring you can restore your data if needed.</p>
                </div>
            </div>
            <div className="accordion">
                <button className="btn accordion-button" onClick={handleAccordionButton}>How do I manage databases in cPanel?</button>
                <div className="accordion-content">
                    <p className="text">You can use the "MySQL Databases" feature to create and manage databases, including adding users and setting permissions.</p>
                </div>
            </div>
            <div className="accordion">
                <button className="btn accordion-button" onClick={handleAccordionButton}>Can I secure my website with SSL using cPanel?</button>
                <div className="accordion-content">
                    <p className="text">Yes, cPanel provides options to install SSL certificates easily, helping to secure your website and improve SEO.</p>
                </div>
            </div>
            <div className="accordion">
                <button className="btn accordion-button" onClick={handleAccordionButton}>What are subdomains, and how can I create them in cPanel?</button>
                <div className="accordion-content">
                    <p className="text">Subdomains are extensions of your main domain. You can create them in the "Subdomains" section by entering the subdomain name and selecting the main domain.</p>
                </div>
            </div>
            <div className="accordion">
                <button className="btn accordion-button" onClick={handleAccordionButton}>Is cPanel user-friendly for beginners?</button>
                <div className="accordion-content">
                    <p className="text">Yes, cPanel is designed to be intuitive, making it accessible for beginners while also providing advanced features for experienced users.</p>
                </div>
            </div>
            <div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>How can I reset my cPanel password?</button>
    <div className="accordion-content">
        <p className="text">You can reset your cPanel password via your hosting provider's dashboard or by contacting their support team.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>What is the importance of backups in cPanel?</button>
    <div className="accordion-content">
        <p className="text">Backups are crucial for recovering your website data in case of data loss, hacks, or server issues.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>Can I use cPanel on mobile devices?</button>
    <div className="accordion-content">
        <p className="text">Yes, cPanel is accessible via mobile devices, though some features may be limited compared to the desktop version.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>How do I install a WordPress site using cPanel?</button>
    <div className="accordion-content">
        <p className="text">Use the Softaculous app installer in cPanel to choose WordPress, fill in the necessary details, and click "Install."</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>What is an FTP account in cPanel?</button>
    <div className="accordion-content">
        <p className="text">An FTP account allows you to transfer files between your computer and your web server, facilitating easier file management.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>How can I check my website's traffic in cPanel?</button>
    <div className="accordion-content">
        <p className="text">You can use the "Awstats" or "Visitors" features in cPanel to view detailed statistics about your website's traffic.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>What is the importance of SSL certificates?</button>
    <div className="accordion-content">
        <p className="text">SSL certificates encrypt data transmitted between the user and your website, enhancing security and trustworthiness.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>How do I manage DNS settings in cPanel?</button>
    <div className="accordion-content">
        <p className="text">You can manage DNS settings through the "Zone Editor" or "DNS Manager" sections in cPanel.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>Can I create multiple domains in cPanel?</button>
    <div className="accordion-content">
        <p className="text">Yes, you can create multiple domains as add-on domains within your cPanel account.</p>
    </div>
</div>

               </div>
            </section>
        )
}

export default SharedFaq;