import React from "react";

function Faq()
{

    const handleAccordionButton = (event) =>{
        const accordion = document.querySelectorAll(".accordion");
        if(accordion.length > 0)
        {
            accordion.forEach(acc =>{
                acc.classList.remove("active");
            });
        }
       event.target.closest(".accordion").classList.add("active");
    }
        return (
            <section className="accordion-wrapper">
               <div className="container">
               <h4 className="title">Understanding Domain Names: Common Questions and Answers</h4>
               <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>What is a domain name?</button>
                    <div className="accordion-content">
                        <p className="text">A domain name is a unique address used to identify a website on the internet. 
                            It consists of two main parts: the name (e.g., "example") and the extension (e.g., ".com"). 
                            Together, they create a full address (e.g., "example.com").</p>
                    </div>
                </div>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>How do I choose a domain name?</button>
                    <div className="accordion-content">
                        <p className="text">When choosing a domain name, consider keeping it short, memorable,
                             and relevant to your brand or content. Avoid using complex spellings or numbers. 
                            Make sure it’s easy to pronounce and type.</p>
                    </div>
                </div>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>What is domain registration?</button>
                    <div className="accordion-content">
                        <p className="text">Domain registration is the process of acquiring a domain name for a specific period, 
                            usually one year. 
                            You must register the domain through a registrar to ensure it is 
                            uniquely yours and not used by anyone else.</p>
                    </div>
                </div>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>How long does it take to register a domain?</button>
                    <div className="accordion-content">
                        <p className="text">Domain registration is usually instantaneous. 
                            Once you complete the registration process and payment, 
                            you typically gain immediate ownership of the domain.</p>
                    </div>
                </div>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>Can I transfer my domain to another registrar?</button>
                    <div className="accordion-content">
                        <p className="text">Yes, you can transfer your domain to another registrar. 
                            However, you may need to unlock the domain and obtain an authorization code from your current registrar. 
                            The transfer process can take a few days.</p>
                    </div>
                </div>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>What is a domain extension?</button>
                    <div className="accordion-content">
                        <p className="text">A domain extension, or top-level domain (TLD), is the suffix at the end of a domain name, such as ".com," ".org," or ".net." 
                            Each extension may have specific uses or target audiences..</p>
                    </div>
                </div>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>What happens if my domain expires?</button>
                    <div className="accordion-content">
                        <p className="text">If your domain expires, you will lose ownership, and it may become available for others to register.
                             Most registrars provide a grace period during which you can renew your domain before it is released.</p>
                    </div>
                </div>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>Do I need hosting to use my domain?</button>
                    <div className="accordion-content">
                        <p className="text">While you don’t need hosting to register a domain, you will need hosting to create a 
                            website. The domain points to your hosting server,
                             allowing visitors to access your site.</p>
                    </div>
                </div>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>Can I buy a domain name for someone else?</button>
                    <div className="accordion-content">
                        <p className="text">Yes, you can purchase a domain name on behalf of someone else.
                             During registration, you can provide the recipient's information as the registrant. However,
                             ensure they are aware of the purchase.</p>
                    </div>
                </div>
                <div className="accordion">
                    <button className="btn accordion-button" onClick={handleAccordionButton}>What is WHOIS privacy protection?</button>
                    <div className="accordion-content">
                        <p className="text">WHOIS privacy protection is a service that hides your personal information from the 
                            public WHOIS database, 
                            which displays details about domain registrants. 
                            This helps protect your privacy and reduces spam.</p>
                    </div>
                </div>
               </div>
            </section>
        )
}

export default Faq;