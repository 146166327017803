import React, {useEffect, useState} from 'react';
import {Navigate} from "react-router-dom";



function ArticlePage()
{
    const [pageContent, setPageContent] = useState('');
    const [ApiError, setApiError] = useState(null);
    const [ResponseCode, setResponseCode] = useState(200);


    useEffect(() => {
        let myUrl = window.location.href;
        myUrl = myUrl.split('/');
        let QueryString = myUrl.pop();

       const getPageContent = async (event) => {
           let Api_Url = process.env.REACT_APP_API_URL+'/api/fetch/?page='+QueryString;

           try{
               const response = await fetch(Api_Url,
                   {
                       method : "GET",
                       header : {
                           "content-type" : "application/json",
                           "Accept" : "application/json",
                           "Cache-Control" : "no-cache"
                       }
                   });

               if(!response.ok)
               {
                   setApiError("Unable to connect due to network error!");
               }

               let result = await response.json();

               if(result.status === 200)
               {
                   setPageContent(result.page);
               }else{
                    setResponseCode(400);
               }
           }catch (exception)
           {
               setApiError(exception.message);
           }
       }
        getPageContent();
    }, []);
    if(ResponseCode === 400)
    {
        return <Navigate to={'/error-404'} />
    }

    return (
       <section id="homepage">
           <div className="container">
               <div className="pg-article-wrapper">
                   <div className="card pg-card">
                       <div className="card-header">
                           <h3 className="title">{pageContent.title}</h3>
                       </div>
                       <div className="card-body">
                           <div dangerouslySetInnerHTML={{__html: pageContent.content}}></div>
                       </div>
                   </div>
               </div>
           </div>
       </section>
    )
}

export default ArticlePage;