import React from "react";

function WordpressFaq()
{

    const handleAccordionButton = (event) =>{
        const accordion = document.querySelectorAll(".accordion");
        if(accordion.length > 0)
        {
            accordion.forEach(acc =>{
                acc.classList.remove("active");
            });
        }
       event.target.closest(".accordion").classList.add("active");
    }
        return (
            <section className="accordion-wrapper">
                <div className="container">
                    <h4 className="title">FAQs about our web design projects</h4>
                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>What is web design?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Web design refers to the process of creating the visual aesthetics and
                                layout of a website, ensuring it is user-friendly and effective in conveying
                                information.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>What are the key
                            elements of a good website?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Key elements include a clear layout, responsive design, easy navigation,
                                fast loading speed, engaging content, and strong calls to action.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>Why is responsive
                            design
                            important?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Responsive design ensures that your website functions well on various
                                devices, providing a seamless experience for all users, which is crucial for SEO and
                                user
                                retention.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>How long does it take
                            to
                            design a website?
                        </button>
                        <div className="accordion-content">
                            <p className="text">The timeline varies based on complexity and features, but a typical
                                website can take anywhere from a few weeks to several months to design and launch.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>What is the cost of web
                            design?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Costs vary widely depending on the project's scope, with basic sites
                                starting around a few hundred dollars to more complex designs costing thousands.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>Do you provide ongoing
                            support after launch?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Yes, we offer ongoing support and maintenance to ensure your website
                                remains functional, secure, and up-to-date.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>How do you ensure my
                            website is SEO-friendly?
                        </button>
                        <div className="accordion-content">
                            <p className="text">We incorporate SEO best practices during the design process, including
                                optimized meta tags, responsive layouts, and fast loading speeds to improve
                                visibility.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>Can I update my website
                            content myself?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Yes, we typically use a user-friendly CMS that allows you to easily
                                update content without needing technical skills.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>What is the role of
                            user
                            experience (UX) in web design?
                        </button>
                        <div className="accordion-content">
                            <p className="text">UX design focuses on optimizing the overall experience of users on your
                                site, ensuring it is intuitive and meets their needs effectively.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>Do you offer e-commerce
                            solutions?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Yes, we provide tailored e-commerce solutions, including shopping cart
                                integration, payment gateways, and inventory management features.</p>
                        </div>
                    </div>
                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>What are the payment
                            options for web design services?
                        </button>
                        <div className="accordion-content">
                            <p className="text">We offer flexible payment plans that allow you to choose between monthly
                                installments or a one-time payment for the setup fee, making it easier to manage your
                                budget.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>Are there recurring
                            fees for maintaining the website?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Yes, there are recurring fees for website maintenance, hosting, and
                                updates. We provide a clear breakdown of these costs so you can plan accordingly.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>Can I customize my
                            service package?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Absolutely! We offer customizable service packages that let you select
                                the features you need, helping you align with your business goals and budget.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>Are there any hidden
                            costs?
                        </button>
                        <div className="accordion-content">
                            <p className="text">No, we pride ourselves on transparency. There are no hidden costs; all
                                fees, including setup, monthly, and additional charges, are clearly communicated
                                upfront.</p>
                        </div>
                    </div>

                    <div className="accordion">
                        <button className="btn accordion-button" onClick={handleAccordionButton}>Do you offer discounts
                            for annual payments?
                        </button>
                        <div className="accordion-content">
                            <p className="text">Yes, we provide incentives for clients who choose to pay annually, such
                                as discounts or additional services, encouraging long-term commitments.</p>
                        </div>
                    </div>

                </div>
            </section>
        )
}

export default WordpressFaq;