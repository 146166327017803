import React from "react";

function WordpressFaq()
{

    const handleAccordionButton = (event) =>{
        const accordion = document.querySelectorAll(".accordion");
        if(accordion.length > 0)
        {
            accordion.forEach(acc =>{
                acc.classList.remove("active");
            });
        }
       event.target.closest(".accordion").classList.add("active");
    }
        return (
            <section className="accordion-wrapper">
               <div className="container">
               <h4 className="title">FAQs about WordPress hosting</h4>
               <div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>What is WordPress hosting?</button>
    <div className="accordion-content">
        <p className="text">WordPress hosting is a type of web hosting specifically optimized for WordPress websites, offering features tailored for performance and security.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>What are the different types of WordPress hosting?</button>
    <div className="accordion-content">
        <p className="text">The main types are Shared Hosting, Managed WordPress Hosting, VPS Hosting, and Dedicated Hosting, each offering varying levels of resources and management.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>Do I need to install WordPress myself?</button>
    <div className="accordion-content">
        <p className="text">Many hosting providers offer one-click WordPress installations, making setup easy without technical knowledge.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>Is WordPress hosting secure?</button>
    <div className="accordion-content">
        <p className="text">Yes, reputable providers implement various security measures like firewalls and SSL certificates to protect your website.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>How often are backups made?</button>
    <div className="accordion-content">
        <p className="text">Managed WordPress hosting typically includes automated daily backups, while shared hosting may offer manual options.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>Can I migrate my existing WordPress site to a new host?</button>
    <div className="accordion-content">
        <p className="text">Yes, most hosting providers offer migration assistance or tools to help transfer your site smoothly.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>What happens if my website goes down?</button>
    <div className="accordion-content">
        <p className="text">Many providers offer uptime guarantees and 24/7 support to quickly address any downtime issues.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>Will my site be able to handle high traffic?</button>
    <div className="accordion-content">
        <p className="text">Managed and VPS hosting can handle higher traffic better than shared hosting, so consider scalability options.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>Do I need to know coding to use WordPress hosting?</button>
    <div className="accordion-content">
        <p className="text">No, WordPress is user-friendly, and most providers offer easy-to-use control panels.</p>
    </div>
</div>
<div className="accordion">
    <button className="btn accordion-button" onClick={handleAccordionButton}>How much does WordPress hosting cost?</button>
    <div className="accordion-content">
        <p className="text">Costs vary; shared hosting can start around RM3–RM10 per month, while managed hosting may range from RM20 to RM100+ per month.</p>
    </div>
</div>

               </div>
            </section>
        )
}

export default WordpressFaq;