import React from "react";
import {configFile} from "../../Config/Constant";
import { Link } from "react-router-dom";

function Slider()
{
    return (
        <section id="slider">
            <div className="slider-wrapper">
                <div className="animation">
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                    <div className="ani-item"></div>
                </div>
                <div className="inner-content">
                    <div className="item">
                       <div className="container">
                       <div className="row justify-content-center">
                       <div className="col-sm-12 col-md-6 col-lg-6">
                            <h1 className="title">Web Development Services for Your Business Success</h1>
                            <h2 className="text">Unlock Your Online Potential with Our Lightning-Fast Web Hosting, Backed by 99.9% Uptime and 24/7 Expert Support!</h2>
                        <div className="button-group">
                            <Link className="btn action-btn" to={'/cpanel-hosting/cpanel-hosting'}>get started</Link>
                            <Link className="btn action-btn" to={'/wordpress-hosting/wordpress-hosting'}>learn more</Link>
                        </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                        <picture srcSet={configFile.hosting_banner1.replace(/\.(png|jpg|jpeg)$/i, '.webp')} type="image/webp">
                           <img src={configFile.hosting_banner1}  alt="hosting banner" className="img-fluid" loading="lazy"/>
                           </picture>
                        </div>
                       </div>
                       </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default Slider;