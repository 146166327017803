import React from "react";

export const configFile = {
    'hosting_banner1' : require('./../assets/images/Hosting banner.png'),
    'hosting_banner2' : require('./../assets/images/banner-3.gif'),
    'security_image' : require('./../assets/images/secure-hosting.png'),
    'ssl_image' : require('./../assets/images/ssl-side.png'),
    'high_hosting_image' : require('./../assets/images/hosting-server.png'),
    'cloud_hosting_image' : require('./../assets/images/cloud-scalibility.png'),
    'business_email' : require('./../assets/images/business_email.png'),
    'web_hosting_suppor_image' : require('./../assets/images/support.jpg'),
    'web_design_image' : require('./../assets/images/web_design.png'),
    'marketing_tool_image' : require('./../assets/images/marketing_tool.png'),
    'seo_tool_image' : require('./../assets/images/seo.png'),
    'accounting_software_image' : require('./../assets/images/accounting-software.png'),
    'cloud_gif' : require('./../assets/images/cloud.png'),
    'Web_Hosting_Solutions' : require('./../assets/images/Web_Hosting_Solutions.gif'),
    'Hosting_hero' : require('./../assets/images/avada-hosting-hero-1.png'),
    'power_icon' : require('./../assets/images/power-button.gif'),
    'favicon' : require('./../assets/images/favicon.png'),
    'description' : "Bigeweb Solution hosting and domain provider",
    'author' : "Bigeweb solution",
    'keywords' : "Web development, theme and templates, hosting, professional email",
    'google_site_verification' : "oTJlUNO7mzaPV0cYE2q29wACPPp5TUUoSx0u6GK7NnI",
    'google_ads_account' : "ca-pub-5934397621272516",
    'logo' :  require('./../assets/images/New Logo Black.png'),
    'payment_icon' :  require('./../assets/images/footer-logo-payment.png'),
    'site_language' : 'ms',
    'dotcom' :  require('./../assets/images/dotcom.png'),
    'dotgov' :  require('./../assets/images/dot-gov.jpg'),
    'dotstore' :  require('./../assets/images/dotSTORE.png'),
    'dotbiz' :  require('./../assets/images/dotbiz.png'),
    'dotedu' :  require('./../assets/images/dotedu.png'),
    'dotmy' :  require('./../assets/images/dotmy.png'),
    'domain_image' :  require('./../assets/images/domain.gif'),
    'big_data' :  require('./../assets/images/big-data.gif'),
    'cpanel_hosting' :   require('./../assets/images/Cpanel_hosting.png'),
    'wordpress_hosting' :   require('./../assets/images/wordpress-hosting.png'),
    'database_image' :   require('./../assets/images/database.gif'),
    'server_image' :   require('./../assets/images/secure-server.gif'),
    'helpdesk_image' :   require('./../assets/images/helpdesk.gif'),
    'secure_hosting' :   require('./../assets/images/cyber-security.gif'),
    'wordpress_hosting_image' :   require('./../assets/images/wordpress-secure-hosting-1.png'),
    'no_product' : require('./../assets/images/no-product.png'),
    'contact_us_img' : require('./../assets/images/contact_us2.gif'),
    'web_design_banner' : require('./../assets/images/web design banner.jpg'),
    'web_page_collection' : require('./../assets/images/web_collection.jpg'),
    'auto_repair_site_pic' : require('./../assets/images/auto_repair_site.jpg'),
    'window_blind_site_pic' : require('./../assets/images/window_blind_website.jpg'),
    'hosting_site_pic' : require('./../assets/images/hosting_website.jpg'),
}