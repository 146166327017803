import React, { useEffect } from "react";
import { configFile } from "../../Config/Constant";
import '../../assets/css/homepage.css';
import '../../assets/css/domain.css';
import Slider from "./Slider";
import DomainSelector from "./DomainSelector";
import Ads from "./Ads";
import Feature from "./Feature";
import Faq from "./Faq";
import {useConfig} from "../../Context/ConfigContext";

function DomainChecker()
{
    const configFile = useConfig();
    useEffect(() => {
        if(configFile.show_site_name_after_title === 1)
        {
            document.title = "Discover Available Domain Names | Bigeweb Domain Finder - " + configFile.site_name;
        }else{
            document.title = "Discover Available Domain Names | Bigeweb Domain Finder"
        }
        document.description = "Looking for a domain name? Bigeweb makes it simple! Search our extensive database to find and register the ideal domain for your business or personal project. Start your search now!";
    }, [])
    return (
        <section id="homepage">
        <>
    <Slider />
    <DomainSelector />
    <Feature />
    <Ads />
    <Faq />
    </>
    </section>
    );
}
export default DomainChecker