import {useConfig} from "../../Context/ConfigContext";
import {useEffect} from "react";
import {configFile} from "../../Config/Constant";

function Site_Header_Preference()
{

    const configFile = useConfig();

    useEffect(()=>{

        const site_language = document.querySelector("html");
        if(site_language)
        {
            site_language.lang = configFile.site_language;
        }

        const MetaLinks = [
            {rel : 'icon', href : configFile.favicon},
            {rel : 'canonical', href : window.location.origin},
            {rel : 'apple-touch-icon', href : configFile.favicon},
        ];

        MetaLinks.forEach(mlink => {
            const mLinkElement = document.querySelector(`link[rel='${mlink.rel}']`);
            if(mLinkElement)
            {
                mLinkElement.href= mlink.href;
            }else{
                const newMlinkElement = document.createElement('link');
                newMlinkElement.setAttribute("rel", mlink.rel);
                newMlinkElement.href = mlink.href; // Set the content
                document.head.appendChild(newMlinkElement);
            }
        });


        const metaName = [
            {name: 'description', content : configFile.description},
            {name: 'author', content : configFile.author},
            {name: 'keywords', content : configFile.keywords},
            {name: 'google-site-verification', content : configFile.google_site_verification},
            {name: 'google-adsense-account', content : configFile.google_ads_account}
        ]

      metaName.forEach(tag =>{
          console.log(tag);
          let MetaNameElement = document.querySelector(`meta[name='${tag.name}']`);
          if(MetaNameElement)
          {
              MetaNameElement.content = tag.content;
          }else{
              let newMetaNameElement = document.createElement("meta");
              newMetaNameElement.setAttribute("name", tag.name);
              newMetaNameElement.content = tag.content;
              document.head.append(newMetaNameElement);
          }
      });


        const metaTags = [
            { property: 'og:title', content: configFile.site_name },
            { property: 'og:description', content: configFile.description },
            { property: 'og:image', content: configFile.favicon }
        ];

        metaTags.forEach(tag => {
            let metaElement = document.querySelector(`meta[property='${tag.property}']`);
            if (metaElement) {
                metaElement.content = tag.content;
            } else {
                metaElement = document.createElement("meta");
                metaElement.setAttribute("property", tag.property);
                metaElement.content = tag.content;
                document.head.append(metaElement);
            }
        });
    });
}
export default Site_Header_Preference;