import React, {useEffect} from "react";

function Alert()
{
    return (
        <div className="alert-container">
            <div className="alert alert-success">
                <div className="inner-content">
                    <i className="fa-solid fa-circle-check"></i>
                    <div className="right-content">
                        <h1 className="title">success</h1>
                        <p className="text"></p>
                    </div>
                </div>
                <button className="btn close alert-btn"><i className="fa-solid fa-xmark-circle"></i></button>
            </div>
            <div className="alert alert-danger">
                <div className="inner-content">
                    <i className="fa-solid fa-circle-xmark"></i>
                    <div className="right-content">
                        <h1 className="title">error</h1>
                        <p className="text"></p>
                    </div>
                </div>
                <button className="btn close alert-btn"><i className="fa-solid fa-xmark-circle"></i></button>
            </div>
            <div className="alert alert-warning">
                <div className="inner-content">
                    <i className="fa-solid fa-triangle-exclamation"></i>
                    <div className="right-content">
                        <h1 className="title">warning</h1>
                        <p className="text">warning</p>
                    </div>
                </div>
                <button className="btn close alert-btn"><i className="fa-solid fa-xmark-circle"></i></button>
            </div>
        </div>
    )
}

export default Alert;