import React from "react";
import {ConfigProvider} from "./Context/ConfigContext";
import {MenuProvider} from "./Context/MenuContext";
import Header from "./Frontend/Layouts/Header";
import Footer from "./Frontend/Layouts/Footer";
import {Outlet} from "react-router-dom";
import Site_Header_Preference from "./Frontend/Layouts/Site_Header_Preference";
import './assets/css/constant.css';
import './assets/css/product_detail.css';
import './assets/css/homepage.css';
import './assets/css/page.css';
import './assets/css/design_page.css';
import './assets/css/contact.css';
import './assets/css/keyframes.css';
import './assets/css/media.css';
import './assets/js/validation';
import {CodeSnippetProvider} from "./Context/CodeSnippetContext";
import Alert from "./Config/Alert";
import {CompanyProfileProvider} from "./Context/CompanyProfileContext";

function App()
{
    return (
        <ConfigProvider>
           <MenuProvider>
              <CodeSnippetProvider>
                 <CompanyProfileProvider>
                     <Site_Header_Preference />
                     <Header />
                     <Alert />
                     <Outlet />
                     <Footer />
                 </CompanyProfileProvider>
              </CodeSnippetProvider>
           </MenuProvider>
        </ConfigProvider>
    );
}

export default App;