// utils.js
export function loading_button(btn) {
    btn = document.querySelector(btn);
    btn.setAttribute('disabled', true);
    if( btn.querySelector(".loading-text")){
        btn.querySelector(".loading-text").style.display = 'inline-block';
    }
    if(btn.querySelector(".spinner")){
        btn.querySelector(".spinner").style.display = 'inline-block';
    }
    if(btn.querySelector(".btn-text")){
        btn.querySelector(".btn-text").style.display = 'none';
    }
}

export function stopLoadingButton(btn, type) {
    const alertElement = document.querySelector(`.alert-${type}`);
    if (alertElement) {
        alertElement.classList.remove("show");
        alertElement.style.display = 'none';
    }
    btn = document.querySelector(btn);
    btn.removeAttribute("disabled");
    btn.querySelector(".loading-text").style.display = 'none';
    btn.querySelector(".spinner").style.display = 'none';
    btn.querySelector(".btn-text").style.display = 'inline-block';
    btn.closest("form").querySelectorAll(".form-control").forEach(input => input.classList.remove("is-invalid"));
    btn.closest("form").querySelectorAll(".form-select").forEach(select => select.classList.remove("is-invalid"));
    btn.closest("form").querySelectorAll(".form-check-input").forEach(input => input.classList.remove("is-invalid"));
    btn.closest("form").querySelectorAll(".image-file-uploader").forEach(input => input.classList.remove("error"));
}

export function showSuccessMessage(btn, res) {
    btn = document.querySelector(btn);
    btn.closest("body").querySelector(".alert-success").classList.add("show");
    btn.closest("body").querySelector(".alert-success").style.display = "inline-block";
    btn.closest("body").querySelector(".alert-success").querySelector(".text").textContent = res;
}

export function showErrorMessage(btn, res) {
    btn = document.querySelector(btn);
    btn.closest("body").querySelector(".alert-danger").classList.add("show");
   btn.closest("body").querySelector(".alert-danger").style.display = "inline-block";
  btn.closest("body").querySelector(".alert-danger").querySelector(".text").textContent = res;
}

export function showWarningMessage(btn, res) {
    btn = document.querySelector(btn);
    btn.closest("body").querySelector(".alert-warning").classList.add("show");
    btn.closest("body").querySelector(".alert-warning").style.display = "inline-block";
    btn.closest("body").querySelector(".alert-warning").querySelector(".text").textContent = res;

}

export function showDangerButton(btn, res) {
    const alertElement = document.querySelector(".alert-danger");
    if (alertElement) {
        alertElement.classList.add("show");
        alertElement.style.display = 'inline-block';

        alertElement.querySelector(".text").innerHTML = ''; // Clear previous messages
        for (const key in res) {
            if (res.hasOwnProperty(key)) {
               if(document.querySelector("input[name='"+key+"']"))
               {
                   document.querySelector("input[name='"+key+"']").classList.add("is-invalid");
               }
                if(document.querySelector("#"+key))
                {
                    document.querySelector("#"+key).classList.add("is-invalid");
                }
                if(document.querySelector("."+key))
                {
                    document.querySelector("."+key).classList.add("is-invalid");
                }

                alertElement.querySelector(".text").innerHTML += `<li>${res[key]}</li>`;
            }
        }
    }
}
