import React, {useEffect} from "react";
import '../../assets/css/homepage.css';
import Slider from "./Slider";
import Domain from "./Domain";
import Chosen from "./Chosen";
import What_we_offer from "./What_we_offer";
import Info from "./Info";
import Info_Right from "./Info_Right";
import Newsletter from "./Newsletter";
import {useConfig} from "../../Context/ConfigContext";

function Homepage()
{
    const configFile = useConfig();
    useEffect(() => {
        if(configFile.show_site_name_after_title === 1)
        {
            document.title = "Web Development Services - " + configFile.site_name;
        }else{
            document.title = "Web Development Services"
        }
        document.description = "Expert web development services to elevate your business.";
    }, []);
    return (
        <section id="homepage">
            <>
        <Slider />
        <Domain />
        <Chosen />
        <What_we_offer />
        <Info />
        <Info_Right />
        <Newsletter />
        </>
        </section>
    );
}

export default Homepage;