import React, {createContext, useContext, useState} from "react";

const CompanyProfileContext = createContext();

export const useCompanyProfile = () => {
    return useContext(CompanyProfileContext);
}

export const CompanyProfileProvider = ({children}) => {
    const [businessInfo, setBusinessInfo] = useState({
        "default_tel_code" : "+60",
        "address" : ''
    });


    return (
      <CompanyProfileContext.Provider value={businessInfo}>
          {children}
      </CompanyProfileContext.Provider>
    );
}