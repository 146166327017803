import React, { useEffect } from "react";
import FeaturePricing from "./FeaturePricing";
import About from './About';
import WordpressFaq from "./WordpressFaq";
import {ProductProvider} from "../../Context/ProductContext";
import Slider from "./Slider";

function WordpressHosting()
{
    return (
        <ProductProvider>
            <section id="homepage">
                <>
                    <Slider/>
                    <FeaturePricing/>
                    <About/>
                    <WordpressFaq/>
                </>
            </section>
        </ProductProvider>
    );
}

export default WordpressHosting;