import react from 'react-dom';
import Topbar from './Topbar';
import '../../assets/css/header.css';
import Navbar from "./Navbar";

function Header()
{
    return(
       <>
       <header id="header">
        <Topbar />
        <Navbar />
       </header>
       </>
    );
}

export default Header;