import react from "react-dom";
import {useConfig} from "../../Context/ConfigContext";

function Topbar()
{
    const configFile = useConfig();
    return (
             <div className="header-top">
        <div className="container">
            <div className="flex-container">
                <ul className="flex-item-left flex-item">
                    <li className="item">
                        <a href={`tel:${configFile.hotline}`} className="link" aria-label="hotline">
                            <span className="icon"><i className="fa-solid fa-phone-volume"></i></span>
                            <span className="text">{configFile.hotline}</span>
                        </a>
                    </li>
                    <li className="item">
                        <a href={`mailto:${configFile.contact_email}`} className="link" aria-label="email us">
                            <span className="icon"><i className="fa-solid fa-envelope"></i></span>
                        <span className="text text-lowercase">{configFile.contact_email}</span>
                        </a>
                    </li>
                </ul>
                <ul className="flex-item-right flex-item">
                    <li className="item">
                        <a href="https://bigeweb.com/billing/index.php?rp=/announcements" className="link" aria-label="announcement">
                            <span className="icon"><i className="fa-solid fa-bullhorn"></i></span>
                           <span className="text"> announcement</span>
                        </a>
                    </li>
                    <li className="item">
                        <a href="https://bigeweb.com/billing/index.php?rp=/knowledgebase" className="link" aria-label="knowledge base">
                            <span className="icon"><i className="fa-brands fa-firefox-browser"></i></span>
                           <span className="text"> knowledgebase</span>
                        </a>
                    </li>
                    <li className="item">
                        <a href="https://bigeweb.com/billing/cart.php?a=view" className="link" aria-label="cart">
                            <span className="icon"><i className="fa-solid fa-cart-shopping"></i></span>
                          <span className="text">  cart</span>
                        </a>
                    </li>
                    <li className="item">
                        <a href={`https://wa.me${configFile.hotline}`} className="link" target="_blank" aria-label="whatsapp us">
                            <span className="icon"><i className="fa-brands fa-whatsapp"></i></span>
                            <span className="text">
                                 whatsApp
                            </span>
                        </a>
                    </li>
                    <li className="item">
                        <a href="https://bigeweb.com/billing/index.php?rp=/login" className="link" aria-label="sign in">
                            <span className="icon"><i className="fa-solid fa-user-large"></i></span>
                           <span className="text"> sign in</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    );
}

export default Topbar;