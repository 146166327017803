import React from "react";
import { configFile } from "../../Config/Constant";

function About()
{
    return (
        <section className="about-product">
            <div className="container">
                <h1 className="title">why should you choose us?</h1>
                <div className="card-group">
                    <div className="card">
                        <div className="card-header">
                            <img src={configFile.server_image} alt="server image" className="img-fluid"/>
                        </div>
                        <div className="card-body">
                        <h4 className="title">99.9% uptime guarrantee</h4>
                        <p className="text">Our server hosting services are based in Singapore, offering optimal performance and 
                            reliability for your needs.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <img src={configFile.secure_hosting} alt="server image" className="img-fluid"/>
                        </div>
                        <div className="card-body">
                            <h4 className="title">safe and secure</h4>
                        <p className="text">Our server hosting ensures a safe and secure environment, utilizing 
                            advanced security protocols to protect your data.</p>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <img src={configFile.helpdesk_image} alt="server image" className="img-fluid"/>
                        </div>
                        <div className="card-body">
                            <h4 className="title"><sup>24</sup>/<sub>7</sub> dedicated support</h4>
                        <p className="text">We offer 24/7 customer support to assist you anytime, 
                            ensuring your hosting experience is smooth and reliable.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default About;