import React from "react";
import {configFile} from "../../Config/Constant";
import {Link} from "react-router-dom";

function NoPage()
{
       return (
       <section id="homepage">
           <div className="error-container-wrapper">
               <div className="container">
                   <img src={configFile.no_product} alt="no product" className="img-fluid" loading="lazy"/>
                   <h2>Page not found</h2>
                   <p>It seems we can't find what you're looking for. Perhaps searching can help or go back to.
                       <Link to={"/"} className={"back-link"}>Homepage</Link>
                   </p>
               </div>
           </div>
       </section>
       );
}
export default NoPage;