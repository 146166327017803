import React from "react";
import { configFile } from "../../Config/Constant";

function Chosen()
{
    return (
        <section className="choosen-template">
            <div className="header">
                <p className="text-title">bigeweb hosting</p>
                <h1 className="title">why <span className="colorful">should you choose us?</span></h1>
            </div>
            <div className="card-wrapper">
                <div className="container">
                <div className="card">
                <picture srcSet={configFile.security_image.replace(/\.(png|jpg|jpeg)$/i, '.webp')} type="image/webp">
                    <img className="img-fluid" src={configFile.security_image} alt="security image" loading="lazy"/>
                    </picture>
                   <div className="card-body">
                    <h1 className="title">security</h1>
                    <p className="text">Our AI firewall utilizes advanced machine learning techniques to analyze 
                        network traffic and block malicious activity in real time, 
                        providing an extra layer of security for your virtual private server.</p>
                    </div>
                </div>
                <div className="card">
                <picture srcSet={configFile.ssl_image.replace(/\.(png|jpg|jpeg)$/i, '.webp')} type="image/webp">
                    <img className="img-fluid" src={configFile.ssl_image} alt="ssl image" loading="lazy"/>
                    </picture>
                   <div className="card-body">
                    <h2 className="title">ssl</h2>
                    <p className="text">We provide free SSL certificate and this let you to encrypts your website
                        content and let your visitors know that you website is trustworthy and secure.</p>
                    </div>
                </div>
                <div className="card">
                <picture srcSet={configFile.high_hosting_image.replace(/\.(png|jpg|jpeg)$/i, '.webp')} type="image/webp">
                    <img className="img-fluid" src={configFile.high_hosting_image} alt="high hosting service image"/>
                    </picture>
                   <div className="card-body">
                    <h3 className="title">high speed server</h3>
                    <p className="text">Top-brand servers with high-quality Intel
                         CPUs and RAM for consistently high performance on web content delivery.</p>
                    </div>
                </div>
                <div className="card">
                <picture srcSet={configFile.web_design_image.replace(/\.(png|jpg|jpeg)$/i, '.webp')} type="image/webp">
                    <img className="img-fluid" src={configFile.web_design_image} alt="cloud hosting"/>
                    </picture>
                   <div className="card-body">
                    <h4 className="title">cloud hosting</h4>
                    <p className="text">Create your website on a virtual network which allow your website to run smoothly with 
                    scalable resources and enhanced reliability.
                    </p>
                    </div>
                </div>
                <div className="card">
                <picture srcSet={configFile.business_email.replace(/\.(png|jpg|jpeg)$/i, '.webp')} type="image/webp">
                    <img className="img-fluid" src={configFile.business_email} alt="business email"/>
                    </picture>
                   <div className="card-body">
                    <h5 className="title">business email</h5>
                    <p className="text">Get professional email service that uses your company's domain name, enhancing your brand's credibility.
                    </p>
                    </div>
                </div>
                <div className="card">
                <picture srcSet={configFile.web_hosting_suppor_image.replace(/\.(png|jpg|jpeg)$/i, '.webp')} type="image/webp">
                    <img className="img-fluid" src={configFile.web_hosting_suppor_image} alt="support image"/>
                    </picture>
                   <div className="card-body">
                    <h6 className="title">worry about getting support?</h6>
                    <p className="text">We have real human to assist you in times of trouble and worries. Just hit on the support button and
                         we will be glad to answer your enquiry within short period
                    </p>
                    </div>
                </div>
                </div>
            </div>
        </section>
    );
}

export default Chosen;